@mixin font-with-normal-line-height($font-size) {
  color: $ABUS-Deep-Grey;
  font-size: $font-size;
  line-height: $font-size + 8;
}

@mixin font-with-extended-line-height($font-size) {
  color: $ABUS-Deep-Grey;
  font-size: $font-size;
  line-height: $font-size + 10;
}

@mixin TBlack($font-size) {
  color: $ABUS-Deep-Grey;
  font-size: $font-size;
  line-height: $font-size + 8;
  font-family: $font-family-black;
}

@mixin T1() {
  @include font-with-normal-line-height(16px);
  font-family: $font-family-black;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

@mixin T2() {
  @include font-with-normal-line-height(16px);
  font-family: $font-family-bold;
  text-transform: uppercase;
  letter-spacing: 0.032em;
}

@mixin T3() {
  @include font-with-normal-line-height(16px);
  font-family: $font-family-regular;
  letter-spacing: 0.024em;
}

@mixin T4() {
  @include font-with-extended-line-height(14px);
  font-family: $font-family-black;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

@mixin T5() {
  @include font-with-extended-line-height(14px);
  font-family: $font-family-bold;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

@mixin T6() {
  @include font-with-extended-line-height(14px);
  font-family: $font-family-regular;
  letter-spacing: 0.04em;
}

@mixin T7() {
  @include font-with-extended-line-height(14px);
  font-family: $font-family-bold;
  letter-spacing: 0.04em;
}

@mixin T8() {
  @include font-with-normal-line-height(14px);
  font-family: $font-family-regular;
  line-height: 16px;
  letter-spacing: 0.026em;
}

%S {
  text-transform: uppercase;
  color: $ABUS-Red;
  letter-spacing: 0.2em;
}

@mixin S1() {
  @extend %S;
  font-family: $font-family-black;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.024em;
  @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 24px;
  }
}

@mixin S2() {
  @extend %S;
  font-family: $font-family-black;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.032em;
  @include media-breakpoint-down(md) {}
  @include media-breakpoint-down(sm) {
      letter-spacing: 0.02em;
  }
}

%C {
  color: $ABUS-Deep-Grey;
}

@mixin C1() {
  @extend %C;
  font-family: $font-family-regular;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.024em;
}

@mixin C1B() {
  @extend %C;
  @include C1();
  font-family: $font-family-black;
}

@mixin C2() {
  @extend %C;
  font-family: $font-family-regular;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.032em;
  @include media-breakpoint-down(md) {}
  @include media-breakpoint-down(sm) {
      letter-spacing: 0.024em;
  }
}

@mixin C2B() {
  @extend %C;
  @include C2();
  font-family: $font-family-black;
}