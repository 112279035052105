@import '../../../assets/index.scss';
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-size: 50px;
    font-family: $font-family-regular;
    color: $ABUS-White;
    line-height: 80px;
    text-align: center;
    background: $ABUS-Red;
    display:inline-block;
}