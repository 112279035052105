@import '../../../assets/index.scss';
.abus-data-grid {
  font-family: $font-family-regular;
  tbody {
    td {
      word-break: break-all;
    }

    .btn-link {
      border: 0;
      border-collapse: collapse;
    }
  }
}
