@import "./mixins/typography-mixins";
@import "./variables/abus-styleguid-variables";
$font-family-regular: "ABUS Fago Pro-Regular", Arial !default;
$font-family-bold: "ABUS Fago Pro-Bold", Arial !default;
$font-family-black: "ABUS Fago Pro-Black", Arial !default;

@media (max-width: 767px) {
    #onetrust-pc-sdk.otPcTab .ot-sdk-row .ot-sdk-columns {
        width: 100% !important;
    }
    button#accept-recommended-btn-handler { float: right !important;}
    .save-preference-btn-handler.onetrust-close-btn-handler{float: left !important;}

}
@media (max-width: 550px) {
    button#accept-recommended-btn-handler, .save-preference-btn-handler.onetrust-close-btn-handler{
        float: right !important;
        clear: both !important;
        width: 100% !important;}
}
@media(min-width: 768px){
    .save-preference-btn-handler.onetrust-close-btn-handler{float: left !important;}
}

#onetrust-pc-sdk .group h3 {
    float: none !important;
}

#onetrust-pc-sdk #pc-policy-text a {
    color: #d40828 !important;
}

.ot-sdk-column {
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.ot-sdk-columns.group.category-menu-switch-handler {
    display: inline-block !important;
    font-family: $font-family-regular !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    letter-spacing: .1em !important;
    line-height: 14px !important;
    -webkit-appearance: none !important;
    background: transparent !important;
    border: 3px solid #d40828 !important;
    color: #d40828 !important;
    cursor: pointer !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    min-width: 150px !important;
    padding: 11px 1em 9px !important;
    text-align: center !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
}

.ot-sdk-columns.group.category-menu-switch-handler h3 {
    font-family: $font-family-regular !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    letter-spacing: .1em !important;
    line-height: 14px !important;
    color: #d40828 !important;
    text-align: center !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
}

.ot-sdk-columns.group.category-menu-switch-handler:hover h3, .ot-sdk-columns.group.category-menu-switch-handler:hover, .ot-sdk-columns.group.category-menu-switch-handler.active-group, .ot-sdk-columns.group.category-menu-switch-handler.active-group h3 {
    background: #d40828 !important;
    color: #fff !important;

}


#onetrust-policy-text a:focus {
    outline: none;
}

.ot-sdk-column .ot-sdk-columns.category-menu-switch-handler {
    /*background-color: transparent !important;
    height: 38px;
    border-radius: 2px;
    min-height: 0;
    max-height: none;
    max-width: none;
    clear: none;
    float: none;
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    text-shadow: none;
    border: 3px solid #d40828 !important;*/
}

#onetrust-pc-sdk.otPcTab {
    border-color: #001e38 !important;
}

#onetrust-pc-sdk .always-active {
    color: #001e38 !important;
}

.cookie-subgroups-container .accordion-header:focus {
    outline: none !important;
}

#onetrust-pc-sdk .toggle .checkbox input:checked + label:after {
    background: #001e38 !important;
}

#onetrust-pc-sdk .toggle .checkbox input:checked + label {
    background: #999 !important;
}

.pc-header {
    background-color: #001e38 !important;
}

#onetrust-pc-sdk .pc-logo-container {
    margin-left: 0px !important;
}

h2#pc-title {
    color: #fff !important;
}

h2#pc-title::before {
    background: none;
}

div#onetrust-banner-sdk {
    border-top: 1px solid #d40828;
}
div#onetrust-banner-sdk:focus, div#onetrust-banner-sdk {
    outline: 1px solid #d40828 !important;
    outline-offset: -1px  !important;
}
div#onetrust-button-group button:focus {
    outline: none !important;
}
div#onetrust-button-group {
    padding-top: 24px;
}

.pc-close-btn-container:focus, .pc-close-btn-container:active, .ot-sdk-columns:focus, a#close-pc-btn-handler:focus {
    outline: none !important;
}

#close-pc-btn-handler, a#close-pc-btn-handler {
    background-image: url("") !important;
    color: #fff;
}

#close-pc-btn-handler::after, a#close-pc-btn-handler::after {
    display: inline-block;
    content: "\00d7";
    font-size: 40px;
    line-height: 40px;
    margin-top: -10px;
    color: #fff;
}
#close-pc-btn-handler:focus, a#close-pc-btn-handler:focus {
    outline-color: transparent !important
}
a#close-pc-btn-handler:hover::after, #close-pc-btn-handler:hover::after {
    color: #d40828 !important;
}

#onetrust-pc-sdk #pc-policy-text a {
    color: #d40828;
}

button#accept-recommended-btn-handler {
    width: auto !important;
    font-family: ABUS Fago Pro, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    letter-spacing: .1em !important;
    line-height: 14px !important;
    -webkit-appearance: none !important;
    background: transparent !important;
    border: 3px solid #d40828 !important;
    color: #d40828 !important;
    cursor: pointer !important;
    margin: 6px !important;
    min-width: 150px !important;
    padding: 11px 16px 9px !important;
    text-align: center !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    -webkit-transition: color .25s, background-color .25s, border-color .25s ease-in-out !important;
    transition: color .25s, background-color .25s, border-color .25s ease-in-out !important;
    will-change: color, background-color, border-color !important;
    box-sizing: border-box !important;
    user-select: none !important;
    white-space: pre !important;
    align-items: flex-start !important;
    -webkit-box-direction: normal !important;
}

button#ot-sdk-btn, button#onetrust-pc-btn-handler, button#onetrust-reject-all-handler, button#onetrust-accept-btn-handler, button.save-preference-btn-handler.onetrust-close-btn-handler {
    display: inline-block !important;
    width: auto !important;
    font-family: ABUS Fago Pro, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    letter-spacing: .1em !important;
    line-height: 14px !important;
    -webkit-appearance: none !important;
    background: transparent !important;
    border: 3px solid #d40828 !important;
    color: #d40828 !important;
    cursor: pointer !important;
    margin: 6px !important;
    min-width: 150px !important;
    padding: 11px 16px 9px !important;
    text-align: center !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
    -webkit-transition: color .25s, background-color .25s, border-color .25s ease-in-out !important;
    transition: color .25s, background-color .25s, border-color .25s ease-in-out !important;
    will-change: color, background-color, border-color !important;
    box-sizing: border-box !important;
    user-select: none !important;
    white-space: pre !important;
    align-items: flex-start !important;
    -webkit-box-direction: normal !important;
}

button#onetrust-pc-btn-handler, button#onetrust-reject-all-handler {
    /*color: #999 !important;
    border: 3px solid #999 !important;*/
}

button#ot-sdk-btn:hover, button#onetrust-pc-btn-handler:hover, button#onetrust-reject-all-handler:hover, button#onetrust-accept-btn-handler:hover, button.save-preference-btn-handler.onetrust-close-btn-handler:hover, button#accept-recommended-btn-handler:hover {
    color: #fff !important;
    text-decoration: none !important;
    background-color: #d40828 !important;
    opacity: 1 !important;
}

button#onetrust-pc-btn-handler:hover, button#onetrust-reject-all-handler:hover {
    /*background-color: #999 !important;*/
}

#onetrust-policy-text a {
    color: #d40828 !important;
    font-weight: bold;
}

a.category-host-list-btn.category-host-list-handler, #onetrust-pc-sdk .category-vendors-list-handler, #onetrust-pc-sdk .category-host-list-handler{
    text-decoration: underline !important;
    color: #002f5c !important;
}
#onetrust-pc-sdk .category-vendors-list-handler:hover, #onetrust-pc-sdk .category-host-list-handler:hover {
    color: #d40828 !important;
}
#onetrust-pc-sdk #pc-title:before {
    content: "" !important;

}

#onetrust-pc-sdk #hosts-list-container .host-option-group li.vendor-host{
    background-color: #fff !important;
    color: #414141 !important;
}

#onetrust-pc-sdk .back-btn-handler:focus{
    border: none !important;
    outline: none !important;
}

div#onetrust-banner-sdk {
    font-family: $font-family-regular !important;
}

.ot-sdk-cookie-policy.ot-sdk-container, .ot-sdk-cookie-policy.ot-sdk-container .cookie-policy-description, #ot-sdk-cookie-policy #cookie-policy-description, p.ot-sdk-cookie-policy-group-desc{
    font-family: $font-family-regular !important;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #000000 !important;
    letter-spacing: .024em;
}

#ot-sdk-cookie-policy #cookie-policy-title {
    color: #000000 !important;
    text-transform: uppercase !important;
    font-family: $font-family-black !important;
    font-display: swap !important;
    letter-spacing: 0.05em !important; 
    font-size: 48px !important;
    line-height: 48px !important;
    margin-bottom: 10px !important;

    @include media-breakpoint-down(sm) {
        font-size: 40px !important;
        line-height: 40px !important;
        letter-spacing: 0.03em !important;
    }
}

h4.ot-sdk-cookie-policy-group, h6.cookies-used-header{
    color: #000000 !important;
    text-transform: uppercase !important;
    font-family: $font-family-black !important;
    font-display: swap !important;
    letter-spacing: 0.05em !important; 
    font-size: 48px !important;
    line-height: 48px !important;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
}
.ot-sdk-cookie-policy.ot-sdk-container th.table-header.host, .ot-sdk-cookie-policy.ot-sdk-container th.table-header.cookies{
    font-family: $font-family-regular !important;
    color: #002F5C !important;
}

.ot-sdk-cookie-policy.ot-sdk-container .cookies-list li a {
    font-family: $font-family-regular !important;
    color: #002F5C !important;
}
.ot-sdk-cookie-policy.ot-sdk-container .cookies-list li a:hover {
    font-family: $font-family-regular !important;
    color: #DA001A !important;
}

.ot-sdk-container.groups-container .description-container h3.category-header, .ot-sdk-container.groups-container .description-container h3#pc-privacy-header{
    font-family: $font-family-regular !important;
    color: #002F5C !important;
}

.ot-sdk-container.groups-container .description-container a.category-host-list-btn.category-host-list-handler{
    font-family: $font-family-regular !important;
    color: #002F5C !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.ot-sdk-container.groups-container .description-container p#pc-policy-text, .ot-sdk-container.groups-container .description-container p.group-description{
    font-family: $font-family-regular !important;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #414141 !important;
}
#onetrust-pc-sdk #pc-title::after{
    background-color: transparent !important;
}
button#onetrust-accept-btn-handler, button#accept-recommended-btn-handler{
    border-color: #d40828 !important;
    color: #d40828 !important;
}
button#onetrust-accept-btn-handler:hover, button#accept-recommended-btn-handler:hover{
    background-color: #d40828 !important;
}
button.save-preference-btn-handler.onetrust-close-btn-handler{
    border-color: #999 !important;
    color: #999 !important;
}
button.save-preference-btn-handler.onetrust-close-btn-handler:hover{
    background-color: #999 !important;
}