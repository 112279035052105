// Variables defined in ABUS Styleguid https://pascherheinz.frontify.com/d/9uNZ1Y5JP8Vz/gui

/*************************************************
          Colors
*************************************************/
$ABUS-Blue: #001E38;
$ABUS-Red: #D40828;
$ABUS-Light-Blue: #027EFF;
$ABUS-Dark-Red: #B61222;

$ABUS-Dark-Grey: #575557;
$ABUS-White: #FFFFFF;
$ABUS-Paper-White: #F5F5F5;
$ABUS-Light-Grey: #E5E5E5;
$ABUS-Mid-Grey: #D6D6D6;
$ABUS-Grey: #999999;
$ABUS-Deep-Grey: #262626;

$ABUS-Green: green;

$default-border-height: 3px;

$abus-colors: (
  "blue": $ABUS-Blue,
  "red": $ABUS-Red,
  "light-blue": $ABUS-Light-Blue,
  "dark-red": $ABUS-Dark-Red,
  "white": $ABUS-White,
  "paper-white": $ABUS-Paper-White,
  "light-grey": $ABUS-Light-Grey,
  "mid-grey": $ABUS-Mid-Grey,
  "grey": $ABUS-Grey,
  "deep-grey": $ABUS-Deep-Grey
);