@import "../../../assets/index.scss";

$devexresseditorSelector: ".dx-texteditor.dx-editor-underlined";


.abus-tagboxinput-group {
    @at-root #{&}-container {
        position: relative;
        input {
            @include T3();
            width: 100%;
        }
        .dx-tag {
            font-family: $font-family-regular;
        }
        .border-none, 
        .border-none:after, .border-none:before{
           border: 0px;
        }
        .dx-placeholder {
            @include T2();
            color: $ABUS-Grey;
        }
        /* HIGHLIGHTER ================================== */
        .highlight {
            pointer-events: none;
            position: relative;
            display: block;
            transition: none;
            opacity: 0.5;
        }

        .highlight:after,
        .highlight:before {
            content: "";
            height: calc(2.25rem);
            top: calc(-1 * (2.25rem));
            width: 0;
            position: absolute;
            background: $ABUS-Blue;
        }

        .highlight:before {
            left: 50%;
        }

        .highlight:after {
            right: 50%;
        }

        #{$devexresseditorSelector}.dx-state-focused ~ .highlight:after,
        #{$devexresseditorSelector}.dx-state-focused ~ .highlight:before {
            animation: inputHighlighter 0.6s cubic-bezier(0.4, 0.95, 0.02, 1);
        }

        /* ANIMATIONS ================ */
        @keyframes inputHighlighter {
            from {
                width: 0;
                background: #000;
            }

            to {
                width: 50%;
                background: transparent;
            }
        }
    }
}




