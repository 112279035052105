.data-tile {
    padding: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    // display: inline-block;
    border-radius: 10px;
    background: #ffffff;
    box-shadow:  8px 8px 16px #d9d9d9,
                 -8px -8px 16px #ffffff;
}

.data-tile > p {
    font-family:  'ABUS Fago Pro-Bold';
}