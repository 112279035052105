@import "../../assets/index.scss";

.dx-htmleditor-content {
    font-family: ABUS Fago Pro-Regular, Arial;
    padding: 0 0 50px 0;
    line-height: 30px;
    letter-spacing: 0.024em;

    p,
    li {
        @extend .typo-c1;
    }

    h3,
    h4 {
        @extend .typo-h6;
    }

    h5 {
        font-weight: bold;
        font-size: 16px;
    }
    p { font-family: ABUS Fago Pro-Regular, Arial;}
}
