@import "./mixins/typography-mixins";
@import "./variables/abus-styleguid-variables";
$font-family-regular: "ABUS Fago Pro-Regular", Arial !default;
$font-family-bold: "ABUS Fago Pro-Bold", Arial !default;
$font-family-black: "ABUS Fago Pro-Black", Arial !default;

%header-base {
    text-transform: uppercase;
}

body {
    color: #000000;
    font-family: $font-family-black;
    font-display: swap;

    h1,
    .typo-h1 {
        @extend %header-base;
        font-size: 80px;
        line-height: 80px;
        letter-spacing: 0.05em;

        @include media-breakpoint-down(md) {
            font-size: 72px;
            line-height: 72px;
            letter-spacing: 0.04em;
        }

        @include media-breakpoint-down(sm) {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: 0.03em;
        }
    }

    h2,
    .typo-h2 {
        @extend %header-base;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: 0.05em;

        @include media-breakpoint-down(sm) {
            font-size: 40px;
            line-height: 40px;
            letter-spacing: 0.03em;
        }
    }

    h3,
    .typo-h3 {
        @extend %header-base;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 0.05em;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 32px;
            letter-spacing: 0.03em;
        }
    }

    h4,
    .typo-h4 {
        @extend %header-base;
        color: $ABUS-Deep-Grey;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 0.05em;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            letter-spacing: 0.04em;
        }

        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 28px;
            letter-spacing: 0.03em;
        }
    }

    h5,
    .typo-h5 {
        @extend %header-base;
        color: $ABUS-Deep-Grey;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: 0.024em;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.02em;
        }

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.016em;
        }
    }

    h6,
    .typo-h6 {
        @extend %header-base;
        font-family: $font-family-bold;
        color: $ABUS-Deep-Grey;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.024em;

        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.02em;
        }

        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 0.016em;
        }
    }

    .typo-s1 {
        @include S1();
    }

    .typo-s2 {
        @include S2();
    }

    .typo-c1 {
        @include C1();
    }

    .typo-c1-bold {
        @include C1B();
    }

    .typo-c2 {
        @include C2();
    }

    .typo-c2-bold {
        @include C2B();
    }

    .typo-t1 {
        @include T1();
    }

    .typo-t2 {
        @include T2();
    }

    .typo-t3 {
        @include T3();
    }

    .typo-t4 {
        @include T4();
    }

    .typo-t5 {
        @include T5();
    }

    .typo-t6 {
        @include T6();
    }

    .typo-t7 {
        @include T7();
    }

    .typo-t8 {
        @include T8();
    }

    .typo-highlight {
        color: $ABUS-Dark-Red;
        font-weight: bold;
    }

    @each $abus-color, $i in $abus-colors {
        .color-#{$abus-color} {
            color: $i !important;
        }
    }
}
