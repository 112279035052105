@font-face {
    font-family: 'icomoon';
    src:  url('./icomoon.eot?hva2co');
    src:  url('./icomoon.eot?hva2co#iefix') format('embedded-opentype'),
      url('./icomoon.ttf?hva2co') format('truetype'),
      url('./icomoon.woff?hva2co') format('woff'),
      url('./icomoon.svg?hva2co#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-size: 1.5em;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: bottom;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-icon_control_arrow_down:before {
    content: "\e900";
  }
  .icon-icon_control_arrow_right:before {
    content: "\e901";
  }
  .icon-icon_control_arrow_up:before {
    content: "\e902";
  }
  .icon-icon_control_arrows_up_down:before {
    content: "\e903";
  }
  .icon-icon_control_callout:before {
    content: "\e904";
  }
  .icon-icon_control_check:before {
    content: "\e905";
  }
  .icon-icon_control_confirm:before {
    content: "\e906";
  }
  .icon-icon_control_direction:before {
    content: "\e907";
  }
  .icon-icon_control_download:before {
    content: "\e908";
  }
  .icon-icon_control_edit:before {
    content: "\e909";
  }
  .icon-icon_control_error:before {
    content: "\e90a";
  }
  .icon-icon_control_filter_set .path1:before {
    content: "\e90b";
    color: rgb(38, 38, 38);
  }
  .icon-icon_control_filter_set .path2:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(11, 106, 231);
  }
  .icon-icon_control_filter_set .path3:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(38, 38, 38);
  }
  .icon-icon_control_filter:before {
    content: "\e90e";
  }
  .icon-icon_control_locate:before {
    content: "\e90f";
  }
  .icon-icon_control_minus:before {
    content: "\e910";
  }
  .icon-icon_control_pin:before {
    content: "\e911";
  }
  .icon-icon_control_play:before {
    content: "\e912";
  }
  .icon-icon_control_plus:before {
    content: "\e913";
  }
  .icon-icon_control_reset:before {
    content: "\e914";
  }
  .icon-icon_control_settings:before {
    content: "\e915";
  }
  .icon-icon_control_size:before {
    content: "\e916";
  }
  .icon-icon_control_trash:before {
    content: "\e917";
  }
  .icon-icon_graphical_boat .path1:before {
    content: "\e918";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_boat .path2:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_clock .path1:before {
    content: "\e91a";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_clock .path2:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_fingeprint .path1:before {
    content: "\e91c";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_fingeprint .path2:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_fire .path1:before {
    content: "\e91e";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_fire .path2:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_heartrate .path1:before {
    content: "\e920";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_heartrate .path2:before {
    content: "\e921";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_helmet .path1:before {
    content: "\e922";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_helmet .path2:before {
    content: "\e923";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_juniorcare .path1:before {
    content: "\e924";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_juniorcare .path2:before {
    content: "\e925";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_line .path1:before {
    content: "\e926";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_line .path2:before {
    content: "\e927";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_lock .path1:before {
    content: "\e928";
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_lock .path2:before {
    content: "\e929";
    margin-left: -1em;
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_motorcycle .path1:before {
    content: "\e92a";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_motorcycle .path2:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_quad .path1:before {
    content: "\e92c";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_quad .path2:before {
    content: "\e92d";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_snowmobile .path1:before {
    content: "\e92e";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_snowmobile .path2:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_tuersicherheit .path1:before {
    content: "\e930";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_tuersicherheit .path2:before {
    content: "\e931";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_graphical_video .path1:before {
    content: "\e932";
    color: rgb(38, 38, 38);
  }
  .icon-icon_graphical_video .path2:before {
    content: "\e933";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_navigation_cart_filled .path1:before {
    content: "\e934";
    color: rgb(38, 38, 38);
  }
  .icon-icon_navigation_cart_filled .path2:before {
    content: "\e935";
    margin-left: -1em;
    color: rgb(212, 8, 40);
  }
  .icon-icon_navigation_cart:before {
    content: "\e936";
  }
  .icon-icon_navigation_close:before {
    content: "\e937";
  }
  .icon-icon_navigation_contact:before {
    content: "\e938";
  }
  .icon-icon_navigation_dealersearch:before {
    content: "\e939";
  }
  .icon-icon_navigation_menu:before {
    content: "\e93a";
  }
  .icon-icon_navigation_newsletter:before {
    content: "\e93b";
  }
  .icon-icon_navigation_profile:before {
    content: "\e93c";
  }
  .icon-icon_navigation_search:before {
    content: "\e93d";
  }
  .icon-icon_social_facebook:before {
    content: "\e93e";
  }
  .icon-icon_social_googleplus:before {
    content: "\e93f";
  }
  .icon-icon_social_instagram:before {
    content: "\e940";
  }
  .icon-icon_social_twitter:before {
    content: "\e941";
  }
  .icon-icon_social_youtube:before {
    content: "\e942";
  }
  .icon-Remove:before {
    content: "\e943";
  }
  .icon-Secure_Connection_Established:before {
    content: "\e944";
  }
  .icon-Pairing:before {
    content: "\e945";
  }
  .icon-Display_VGA_CIF:before {
    content: "\e946";
  }
  .icon-Format_Memory:before {
    content: "\e947";
  }
  .icon-Cut:before {
    content: "\e948";
  }
  .icon-Timer:before {
    content: "\e949";
  }
  .icon-Einrichtungsassistent:before {
    content: "\e94a";
  }
  .icon-Import:before {
    content: "\e94b";
  }
  .icon-Screeneditor:before {
    content: "\e94c";
  }
  .icon-Liveframe:before {
    content: "\e94d";
  }
  .icon-format_SD_card:before {
    content: "\e94e";
  }
  .icon-timeintervall:before {
    content: "\e94f";
  }
  .icon-Slider_Steps:before {
    content: "\e950";
  }
  .icon-Target_Section_to_record:before {
    content: "\e951";
  }
  .icon-Alarm_bei_Videoverlust:before {
    content: "\e952";
  }
  .icon-Time_to_record:before {
    content: "\e953";
  }
  .icon-File_Viewer_Symbol_Movie_unsupported:before {
    content: "\e954";
  }
  .icon-minimize:before {
    content: "\e955";
  }
  .icon-maximize:before {
    content: "\e956";
  }
  .icon-energie_saving:before {
    content: "\e957";
  }
  .icon-energie_saving_mode_5min:before {
    content: "\e958";
  }
  .icon-energie_saving_mode_10min:before {
    content: "\e959";
  }
  .icon-Camera_Menu_Alarm_Out:before {
    content: "\e95a";
  }
  .icon-Camera_Menu_Day-Night:before {
    content: "\e95b";
  }
  .icon-Camera_Menu_Help_Tour:before {
    content: "\e95c";
  }
  .icon-Help_Relais:before {
    content: "\e95d";
  }
  .icon-Device:before {
    content: "\e95e";
  }
  .icon-Camera_Menu_Preset:before {
    content: "\e95f";
  }
  .icon-Preset_Clean:before {
    content: "\e960";
  }
  .icon-Preset_Goto:before {
    content: "\e961";
  }
  .icon-Preset_Revise:before {
    content: "\e962";
  }
  .icon-PTZ_Aperture:before {
    content: "\e963";
  }
  .icon-Channel_offline:before {
    content: "\e964";
  }
  .icon-Config_Device:before {
    content: "\e965";
  }
  .icon-Fisheye:before {
    content: "\e966";
  }
  .icon-PTZ_Focus:before {
    content: "\e967";
  }
  .icon-People_Counting:before {
    content: "\e968";
  }
  .icon-change_user .path1:before {
    content: "\e969";
    color: rgb(0, 0, 0);
  }
  .icon-change_user .path2:before {
    content: "\e96a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-change_user .path3:before {
    content: "\e96b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-change_user .path4:before {
    content: "\e96c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-change_user .path5:before {
    content: "\e96d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-change_user .path6:before {
    content: "\e96e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Redo:before {
    content: "\e96f";
  }
  .icon-mailbox-out:before {
    content: "\e970";
  }
  .icon-Collapse_Menu:before {
    content: "\e971";
  }
  .icon-secure_connection:before {
    content: "\e972";
  }
  .icon-Keypad:before {
    content: "\e973";
  }
  .icon-Prox_Tag:before {
    content: "\e974";
  }
  .icon-Prox_Tag-key:before {
    content: "\e975";
  }
  .icon-timebased_reminder .path1:before {
    content: "\e976";
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path2:before {
    content: "\e977";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path3:before {
    content: "\e978";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path4:before {
    content: "\e979";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path5:before {
    content: "\e97a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path6:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-timebased_reminder .path7:before {
    content: "\e97c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-timebased_reminder .path8:before {
    content: "\e97d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-liveview:before {
    content: "\e97e";
  }
  .icon-camera_is_recording:before {
    content: "\e97f";
  }
  .icon-record_multiple:before {
    content: "\e980";
  }
  .icon-record_settings:before {
    content: "\e981";
  }
  .icon-recording:before {
    content: "\e982";
  }
  .icon-Quality:before {
    content: "\e983";
  }
  .icon-Motion_Sensivity:before {
    content: "\e984";
  }
  .icon-Motion_B:before {
    content: "\e985";
  }
  .icon-Alarm_on_movement:before {
    content: "\e986";
  }
  .icon-Day-Night:before {
    content: "\e987";
  }
  .icon-Statusicon_Bewegung_und_Alarm-In:before {
    content: "\e988";
  }
  .icon-Abus_Logo:before {
    content: "\e989";
  }
  .icon-wAppLoxx:before {
    content: "\e98a";
  }
  .icon-add:before {
    content: "\e98b";
  }
  .icon-user:before {
    content: "\e98c";
  }
  .icon-Access:before {
    content: "\e98d";
  }
  .icon-Devices:before {
    content: "\e98e";
  }
  .icon-ARC_Reporting:before {
    content: "\e98f";
  }
  .icon-Speech_Dialer:before {
    content: "\e990";
  }
  .icon-Memorysettings:before {
    content: "\e991";
  }
  .icon-edit:before {
    content: "\e992";
  }
  .icon-Output_Test:before {
    content: "\e993";
  }
  .icon-SMS_Test:before {
    content: "\e994";
  }
  .icon-Sirene_and_Sound_Test:before {
    content: "\e995";
  }
  .icon-Signal_Strength_Test:before {
    content: "\e996";
  }
  .icon-Remote_Test:before {
    content: "\e997";
  }
  .icon-Prox-Tag-Test:before {
    content: "\e998";
  }
  .icon-Door_Lock_Test:before {
    content: "\e999";
  }
  .icon-E-Mail_Test:before {
    content: "\e99a";
  }
  .icon-Zone_Resistance_Test:before {
    content: "\e99b";
  }
  .icon-ARC_Reporting_Test:before {
    content: "\e99c";
  }
  .icon-Speech_Dialer_Test:before {
    content: "\e99d";
  }
  .icon-User_Holds_Up_Test:before {
    content: "\e99e";
  }
  .icon-Pendants_Test:before {
    content: "\e99f";
  }
  .icon-Wireless_Output:before {
    content: "\e9a0";
  }
  .icon-Wireless_Zones:before {
    content: "\e9a1";
  }
  .icon-Wired_Output:before {
    content: "\e9a2";
  }
  .icon-Wired_Zones:before {
    content: "\e9a3";
  }
  .icon-Reset_User:before {
    content: "\e9a4";
  }
  .icon-User_Access:before {
    content: "\e9a5";
  }
  .icon-Push_Notification:before {
    content: "\e9a6";
  }
  .icon-Voice_Memo:before {
    content: "\e9a7";
  }
  .icon-Security:before {
    content: "\e9a8";
  }
  .icon-Scheduled_Output:before {
    content: "\e9a9";
  }
  .icon-Reporting_Options:before {
    content: "\e9aa";
  }
  .icon-Telefone_Book:before {
    content: "\e9ab";
  }
  .icon-About_Panel:before {
    content: "\e9ac";
  }
  .icon-VOIP_Settings:before {
    content: "\e9ad";
  }
  .icon-Outputs_On-Off:before {
    content: "\e9ae";
  }
  .icon-IP_Zones:before {
    content: "\e9af";
  }
  .icon-About_Devices:before {
    content: "\e9b0";
  }
  .icon-About_GSM-GPRS:before {
    content: "\e9b1";
  }
  .icon-Accessory_Modules:before {
    content: "\e9b2";
  }
  .icon-System_Info:before {
    content: "\e9b3";
  }
  .icon-Control_Devices:before {
    content: "\e9b4";
  }
  .icon-Hardware:before {
    content: "\e9b5";
  }
  .icon-Installer_Details:before {
    content: "\e9b6";
  }
  .icon-Part-Set:before {
    content: "\e9b7";
  }
  .icon-User_Holds_Up:before {
    content: "\e9b8";
  }
  .icon-Pendants:before {
    content: "\e9b9";
  }
  .icon-Time:before {
    content: "\e9ba";
  }
  .icon-wAppLoxx_aufschlieen:before {
    content: "\e9bb";
  }
  .icon-contact_via_mail:before {
    content: "\e9bc";
  }
  .icon-Dynamic_DNS:before {
    content: "\e9bd";
  }
  .icon-VoIP_Dialer:before {
    content: "\e9be";
  }
  .icon-Detector:before {
    content: "\e9bf";
  }
  .icon-SMS:before {
    content: "\e9c0";
  }
  .icon-Read_FAQ:before {
    content: "\e9c1";
  }
  .icon-Write_Installer:before {
    content: "\e9c2";
  }
  .icon-Partitions:before {
    content: "\e9c3";
  }
  .icon-Fast_Forward:before {
    content: "\e9c4";
  }
  .icon-Play_Backwards:before {
    content: "\e9c5";
  }
  .icon-Stop:before {
    content: "\e9c6";
  }
  .icon-Memory:before {
    content: "\e9c7";
  }
  .icon-Mikrofon_ON:before {
    content: "\e9c8";
  }
  .icon-Mikrofon_OFF:before {
    content: "\e9c9";
  }
  .icon-Zoom_IN:before {
    content: "\e9ca";
  }
  .icon-Zoom_Out:before {
    content: "\e9cb";
  }
  .icon-Suche:before {
    content: "\e9cc";
  }
  .icon-Network:before {
    content: "\e9cd";
  }
  .icon-Check:before {
    content: "\e9ce";
  }
  .icon-SD-card:before {
    content: "\e9cf";
  }
  .icon-overwrite-SD-card:before {
    content: "\e9d0";
  }
  .icon-overwrite-SD-card-disable:before {
    content: "\e9d1";
  }
  .icon-Melder:before {
    content: "\e9d2";
  }
  .icon-RSSI:before {
    content: "\e9d3";
  }
  .icon-Calender:before {
    content: "\e9d4";
  }
  .icon-print:before {
    content: "\e9d5";
  }
  .icon-around-the-clock:before {
    content: "\e9d6";
  }
  .icon-Navigation_Bar_Back_Button:before {
    content: "\e9d7";
  }
  .icon-File_Viewer_Symbol_Image:before {
    content: "\e9d8";
  }
  .icon-File_Viewer_Symbol_Movie:before {
    content: "\e9d9";
  }
  .icon-delete:before {
    content: "\e9da";
  }
  .icon-Alarm_Output_close-open:before {
    content: "\e9db";
  }
  .icon-Set_Back:before {
    content: "\e9dc";
  }
  .icon-Event_Search:before {
    content: "\e9dd";
  }
  .icon-Log_Search:before {
    content: "\e9de";
  }
  .icon-TV_Wall:before {
    content: "\e9df";
  }
  .icon-E-Map:before {
    content: "\e9e0";
  }
  .icon-messages:before {
    content: "\e9e1";
  }
  .icon-Tag:before {
    content: "\e9e2";
  }
  .icon-unite_record-screens:before {
    content: "\e9e3";
  }
  .icon-seperate_record-screens:before {
    content: "\e9e4";
  }
  .icon-copy:before {
    content: "\e9e5";
  }
  .icon-Protokoll:before {
    content: "\e9e6";
  }
  .icon-two_screens:before {
    content: "\e9e7";
  }
  .icon-displaysettings:before {
    content: "\e9e8";
  }
  .icon-Statusmeldung_1:before {
    content: "\e9e9";
  }
  .icon-Statusmeldung_2:before {
    content: "\e9ea";
  }
  .icon-Statusmeldung_3:before {
    content: "\e9eb";
  }
  .icon-Statusmeldung_4:before {
    content: "\e9ec";
  }
  .icon-Restore_Config:before {
    content: "\e9ed";
  }
  .icon-Key_Pad_Test:before {
    content: "\e9ee";
  }
  .icon-Push_Notification_Test:before {
    content: "\e9ef";
  }
  .icon-PSU_Test:before {
    content: "\e9f0";
  }
  .icon-Control_Device_Test:before {
    content: "\e9f1";
  }
  .icon-Walk_Test:before {
    content: "\e9f2";
  }
  .icon-Telephone_Call_Test:before {
    content: "\e9f3";
  }
  .icon-Remote:before {
    content: "\e9f4";
  }
  .icon-Record_Message:before {
    content: "\e9f5";
  }
  .icon-Play_Message:before {
    content: "\e9f6";
  }
  .icon-Date_and_Time:before {
    content: "\e9f7";
  }
  .icon-IP_Outputs:before {
    content: "\e9f8";
  }
  .icon-Test:before {
    content: "\e9f9";
  }
  .icon-Activity_Monitor:before {
    content: "\e9fa";
  }
  .icon-GSM-GPRS:before {
    content: "\e9fb";
  }
  .icon-Info-Modules_Intern_Sirenes:before {
    content: "\e9fc";
  }
  .icon-QR_Code:before {
    content: "\e9fd";
  }
  .icon-visible:before {
    content: "\e9fe";
  }
  .icon-invisible:before {
    content: "\e9ff";
  }
  .icon-Dots:before {
    content: "\ea00";
  }
  .icon-Pause:before {
    content: "\ea01";
  }
  .icon-emergency_call:before {
    content: "\ea02";
  }
  .icon-battery_empty:before {
    content: "\ea03";
  }
  .icon-battery_full:before {
    content: "\ea04";
  }
  .icon-blocked:before {
    content: "\ea05";
  }
  .icon-Stream_1:before {
    content: "\ea06";
  }
  .icon-Stream_2 .path1:before {
    content: "\ea07";
    color: rgb(0, 0, 0);
  }
  .icon-Stream_2 .path2:before {
    content: "\ea08";
    margin-left: -1em;
    color: rgb(29, 29, 27);
  }
  .icon-Tours:before {
    content: "\ea09";
  }
  .icon-Help .path1:before {
    content: "\ea0a";
    color: rgb(0, 0, 0);
  }
  .icon-Help .path2:before {
    content: "\ea0b";
    margin-left: -1em;
    color: rgb(29, 29, 27);
  }
  .icon-Help .path3:before {
    content: "\ea0c";
    margin-left: -1em;
    color: rgb(29, 29, 27);
  }
  .icon-PTZ:before {
    content: "\ea0d";
  }
  .icon-Day:before {
    content: "\ea0e";
  }
  .icon-Snapshot:before {
    content: "\ea0f";
  }
  .icon-Alarmout:before {
    content: "\ea10";
  }
  .icon-Day_Auto:before {
    content: "\ea11";
  }
  .icon-Status:before {
    content: "\ea12";
  }
  .icon-Event_Log:before {
    content: "\ea13";
  }
  .icon-Outputs:before {
    content: "\ea14";
  }
  .icon-Camera:before {
    content: "\ea15";
  }
  .icon-Export:before {
    content: "\ea16";
  }
  .icon-Download:before {
    content: "\ea17";
  }
  .icon-Logout:before {
    content: "\ea18";
  }
  .icon-Back:before {
    content: "\ea19";
  }
  .icon-Settings:before {
    content: "\ea1a";
  }
  .icon-Filter:before {
    content: "\ea1b";
  }
  .icon-Row_Arrow:before {
    content: "\ea1c";
  }
  .icon-Modal:before {
    content: "\ea1d";
  }
  .icon-Panel:before {
    content: "\ea1e";
  }
  .icon-Warning:before {
    content: "\ea1f";
  }
  .icon-Alarm:before {
    content: "\ea20";
  }
  .icon-Omit .path1:before {
    content: "\ea21";
    color: rgb(0, 0, 0);
  }
  .icon-Omit .path2:before {
    content: "\ea22";
    margin-left: -1em;
    color: rgb(1, 1, 1);
  }
  .icon-Omit .path3:before {
    content: "\ea23";
    margin-left: -1em;
    color: rgb(1, 1, 1);
  }
  .icon-Omit .path4:before {
    content: "\ea24";
    margin-left: -1em;
    color: rgb(1, 1, 1);
  }
  .icon-Lock_Closed:before {
    content: "\ea25";
  }
  .icon-Cancel:before {
    content: "\ea26";
  }
  .icon-Lock_Open:before {
    content: "\ea27";
  }
  .icon-Ok:before {
    content: "\ea28";
  }
  .icon-PTZ_Arrow:before {
    content: "\ea29";
  }
  .icon-Favourite:before {
    content: "\ea2a";
  }
  .icon-Favourite_Filled:before {
    content: "\ea2b";
  }
  .icon-Globe:before {
    content: "\ea2c";
  }
  .icon-Group:before {
    content: "\ea2d";
  }
  .icon-Refresh:before {
    content: "\ea2e";
  }
  .icon-DragnDrop:before {
    content: "\ea2f";
  }
  .icon-SwitchStay:before {
    content: "\ea30";
  }
  .icon-Camoverview:before {
    content: "\ea31";
  }
  .icon-Share:before {
    content: "\ea32";
  }
  .icon-Play:before {
    content: "\ea33";
  }
  .icon-zoom_in_PTZ:before {
    content: "\ea34";
  }
  .icon-Gridview_Favorit:before {
    content: "\ea35";
  }
  .icon-brightness:before {
    content: "\ea36";
  }
  .icon-volume:before {
    content: "\ea37";
  }
  .icon-zoom_out_PTZ:before {
    content: "\ea38";
  }
  .icon-Needs_Reset:before {
    content: "\ea39";
  }
  .icon-Communications:before {
    content: "\ea3a";
  }
  .icon-Wired_Expanders:before {
    content: "\ea3b";
  }
  .icon-Radio_Expanders:before {
    content: "\ea3c";
  }
  .icon-RF_Repeater:before {
    content: "\ea3d";
  }
  .icon-Upgrade_by_file:before {
    content: "\ea3e";
  }
  .icon-Level-4_auto_upgrade:before {
    content: "\ea3f";
  }
  .icon-Actovated:before {
    content: "\ea40";
  }
  .icon-Tamper:before {
    content: "\ea41";
  }
  .icon-Masking:before {
    content: "\ea42";
  }
  .icon-Fault:before {
    content: "\ea43";
  }
  .icon-WDFault:before {
    content: "\ea44";
  }
  .icon-Social_care_test:before {
    content: "\ea45";
  }
  .icon-Expanders_test:before {
    content: "\ea46";
  }
  .icon-Battery_test:before {
    content: "\ea47";
  }
  .icon-locate_bus_device:before {
    content: "\ea48";
  }
  .icon-detector_signal_strength:before {
    content: "\ea49";
  }
  .icon-doorlock_signal_strength:before {
    content: "\ea4a";
  }
  .icon-External_siren_signal_strength:before {
    content: "\ea4b";
  }
  .icon-Internal_sirens_signal_strength:before {
    content: "\ea4c";
  }
  .icon-Repeater_signal_strength:before {
    content: "\ea4d";
  }
  .icon-Radio_key_signal_strength:before {
    content: "\ea4e";
  }
  .icon-Facilities_On-Off:before {
    content: "\ea4f";
  }
  .icon-Remote_Updates:before {
    content: "\ea50";
  }
  .icon-Bus_Device_Lost:before {
    content: "\ea51";
  }
  .icon-Bus_Device_Found:before {
    content: "\ea52";
  }
  .icon-Duplicate_bus_device:before {
    content: "\ea53";
  }
  .icon-Save-Submit:before {
    content: "\ea54";
  }
  .icon-Telecomms_priority:before {
    content: "\ea55";
  }
  .icon-Focus_:before {
    content: "\ea56";
  }
  .icon-Focus_-:before {
    content: "\ea57";
  }
  .icon-Dashboard:before {
    content: "\ea58";
  }
  .icon-Event_Manager:before {
    content: "\ea59";
  }
  .icon-Audio:before {
    content: "\ea5a";
  }
  .icon-MISC:before {
    content: "\ea5b";
  }
  .icon-OSD:before {
    content: "\ea5c";
  }
  .icon-Private_Zones:before {
    content: "\ea5d";
  }
  .icon-FTP:before {
    content: "\ea5e";
  }
  .icon-NAS:before {
    content: "\ea5f";
  }
  .icon-Detection:before {
    content: "\ea60";
  }
  .icon-Audio_Detection:before {
    content: "\ea61";
  }
  .icon-Human_Detection:before {
    content: "\ea62";
  }
  .icon-Intrusion_Detection .path1:before {
    content: "\ea63";
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path2:before {
    content: "\ea64";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path3:before {
    content: "\ea65";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path4:before {
    content: "\ea66";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path5:before {
    content: "\ea67";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Intrusion_Detection .path6:before {
    content: "\ea68";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path7:before {
    content: "\ea69";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path8:before {
    content: "\ea6a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path9:before {
    content: "\ea6b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path10:before {
    content: "\ea6c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path11:before {
    content: "\ea6d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path12:before {
    content: "\ea6e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Intrusion_Detection .path13:before {
    content: "\ea6f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Tripwire_Detection:before {
    content: "\ea70";
  }
  .icon-Direction:before {
    content: "\ea71";
  }
  .icon-Self-diagnostic_functions:before {
    content: "\ea72";
  }
  .icon-Alarm_input:before {
    content: "\ea73";
  }
  .icon-Tracking-Var1:before {
    content: "\ea74";
  }
  .icon-Tracking-Var2:before {
    content: "\ea75";
  }
  .icon-Tracking-Var3:before {
    content: "\ea76";
  }
  .icon-Tracking_Human:before {
    content: "\ea77";
  }
  .icon-Info:before {
    content: "\ea78";
  }
  .icon-Remove1:before {
    content: "\ea79";
  }
  .icon-No_Message:before {
    content: "\ea7a";
  }
  .icon-Users:before {
    content: "\ea7b";
  }
  .icon-Pin:before {
    content: "\ea7c";
  }
  .icon-Signal_Fail:before {
    content: "\ea7d";
  }
  .icon-Web_Admin:before {
    content: "\ea7e";
  }
  .icon-Upgrade:before {
    content: "\ea7f";
  }
  .icon-Edit_Pen:before {
    content: "\ea80";
  }
  .icon-Help_filled:before {
    content: "\ea81";
  }
  .icon-Phone:before {
    content: "\ea82";
  }
  .icon-Company:before {
    content: "\ea83";
  }
  .icon-Key_lock_plan:before {
    content: "\ea84";
  }
  .icon-Installer:before {
    content: "\ea85";
  }
  .icon-Installers:before {
    content: "\ea86";
  }
  .icon-Remove_Installer:before {
    content: "\ea87";
  }
  .icon-Cloud_Id:before {
    content: "\ea88";
  }
  .icon-Map:before {
    content: "\ea89";
  }
  .icon-News:before {
    content: "\ea8a";
  }
  .icon-Drop_down:before {
    content: "\ea8b";
  }
  .icon-Link:before {
    content: "\ea8c";
  }
  .icon-Location:before {
    content: "\ea8d";
  }
  .icon-Smartphone:before {
    content: "\ea8e";
  }
  .icon-Cloud_connection:before {
    content: "\ea8f";
  }
  .icon-Remove_pairing:before {
    content: "\ea90";
  }
  .icon-Reset_link:before {
    content: "\ea91";
  }
  .icon-Stats:before {
    content: "\ea92";
  }
  .icon-Test_notification:before {
    content: "\ea93";
  }
  .icon-Insert:before {
    content: "\ea94";
  }
  .icon-Row_arrow_left:before {
    content: "\ea95";
  }
  .icon-First_page:before {
    content: "\ea96";
  }
  .icon-Last_page:before {
    content: "\ea97";
  }
  .icon-Arrow_up:before {
    content: "\ea98";
  }
  .icon-Bullet:before {
    content: "\ea99";
  }
  .icon-VP_dots:before {
    content: "\ea9a";
  }
  .icon-Locks:before {
    content: "\ea9b";
  }
  .icon-Key_issue:before {
    content: "\ea9c";
  }
  .icon-Upload:before {
    content: "\ea9d";
  }
  .icon-Check_circle:before {
    content: "\ea9e";
  }
  .icon-Key_up:before {
    content: "\ea9f";
  }
  .icon-Cancel_circle:before {
    content: "\eaa0";
  }
  .icon-Unsecure_connection:before {
    content: "\eaa1";
  }
  .icon-Secure_connection:before {
    content: "\eaa2";
  }
  .icon-24-7_selection:before {
    content: "\eaa3";
  }
  .icon-Day_selection:before {
    content: "\eaa4";
  }
  .icon-Week_selection:before {
    content: "\eaa5";
  }
  .icon-Timeframe_selection .path1:before {
    content: "\eaa6";
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path2:before {
    content: "\eaa7";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path3:before {
    content: "\eaa8";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path4:before {
    content: "\eaa9";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path5:before {
    content: "\eaaa";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path6:before {
    content: "\eaab";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path7:before {
    content: "\eaac";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Timeframe_selection .path8:before {
    content: "\eaad";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path9:before {
    content: "\eaae";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Timeframe_selection .path10:before {
    content: "\eaaf";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path11:before {
    content: "\eab0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Timeframe_selection .path12:before {
    content: "\eab1";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path13:before {
    content: "\eab2";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Timeframe_selection .path14:before {
    content: "\eab3";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Groups:before {
    content: "\eab4";
  }
  .icon-Rooms:before {
    content: "\eab5";
  }
  .icon-Speaker_on:before {
    content: "\eab6";
  }
  .icon-Speaker_off:before {
    content: "\eab7";
  }
  .icon-Gas:before {
    content: "\eab8";
  }
  .icon-RGB_Light_off:before {
    content: "\eab9";
  }
  .icon-RGB_Light_on .path1:before {
    content: "\eaba";
    color: rgb(0, 0, 255);
  }
  .icon-RGB_Light_on .path2:before {
    content: "\eabb";
    margin-left: -1em;
    color: rgb(0, 255, 255);
  }
  .icon-RGB_Light_on .path3:before {
    content: "\eabc";
    margin-left: -1em;
    color: rgb(0, 255, 0);
  }
  .icon-RGB_Light_on .path4:before {
    content: "\eabd";
    margin-left: -1em;
    color: rgb(255, 255, 0);
  }
  .icon-RGB_Light_on .path5:before {
    content: "\eabe";
    margin-left: -1em;
    color: rgb(239, 124, 43);
  }
  .icon-RGB_Light_on .path6:before {
    content: "\eabf";
    margin-left: -1em;
    color: rgb(255, 0, 0);
  }
  .icon-RGB_Light_on .path7:before {
    content: "\eac0";
    margin-left: -1em;
    color: rgb(255, 0, 255);
  }
  .icon-RGB_Light_on .path8:before {
    content: "\eac1";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RGB_Light_color:before {
    content: "\eac2";
  }
  .icon-Curtain_0:before {
    content: "\eac3";
  }
  .icon-Curtain_25:before {
    content: "\eac4";
  }
  .icon-Curtain_50:before {
    content: "\eac5";
  }
  .icon-Curtain_75:before {
    content: "\eac6";
  }
  .icon-Curtain_100:before {
    content: "\eac7";
  }
  .icon-Dimmer:before {
    content: "\eac8";
  }
  .icon-Battery_percentage:before {
    content: "\eac9";
  }
  .icon-Electricity:before {
    content: "\eaca";
  }
  .icon-House_flooding:before {
    content: "\eacb";
  }
  .icon-Humidity_percentage:before {
    content: "\eacc";
  }
  .icon-Light_power:before {
    content: "\eacd";
  }
  .icon-Water:before {
    content: "\eace";
  }
  .icon-Motion_stop:before {
    content: "\eacf";
  }
  .icon-Switch_on .path1:before {
    content: "\ead0";
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path2:before {
    content: "\ead1";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path3:before {
    content: "\ead2";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path4:before {
    content: "\ead3";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path5:before {
    content: "\ead4";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path6:before {
    content: "\ead5";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path7:before {
    content: "\ead6";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path8:before {
    content: "\ead7";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_on .path9:before {
    content: "\ead8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Switch_off .path1:before {
    content: "\ead9";
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path2:before {
    content: "\eada";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path3:before {
    content: "\eadb";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path4:before {
    content: "\eadc";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path5:before {
    content: "\eadd";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path6:before {
    content: "\eade";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path7:before {
    content: "\eadf";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Switch_off .path8:before {
    content: "\eae0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-Switch_off .path9:before {
    content: "\eae1";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-Socket_on:before {
    content: "\eae2";
  }
  .icon-Socket_off:before {
    content: "\eae3";
  }
  .icon-Reed-Switch_open:before {
    content: "\eae4";
  }
  .icon-Reed-Switch_closed:before {
    content: "\eae5";
  }
  .icon-Remote1:before {
    content: "\eae6";
  }
  .icon-Shaker_on:before {
    content: "\eae7";
  }
  .icon-Shaker_off:before {
    content: "\eae8";
  }
  .icon-Smoke:before {
    content: "\eae9";
  }
  .icon-Vibration_on:before {
    content: "\eaea";
  }
  .icon-Vibration_off:before {
    content: "\eaeb";
  }
  .icon-Lamp:before {
    content: "\eaec";
  }
  .icon-Dimmer_off:before {
    content: "\eaed";
  }
  .icon-Flooding_off:before {
    content: "\eaee";
  }
  .icon-Gasmask:before {
    content: "\eaef";
  }
  .icon-No_Smoke:before {
    content: "\eaf0";
  }
  .icon-Fire:before {
    content: "\eaf1";
  }
  .icon-Electricity_Lighning:before {
    content: "\eaf2";
  }
  .icon-Battery_50:before {
    content: "\eaf3";
  }
  .icon-Hygrometer:before {
    content: "\eaf4";
  }
  .icon-Lighbulb_top:before {
    content: "\eaf5";
  }
  .icon-Lighbulb_bottom:before {
    content: "\eaf6";
  }
  .icon-Battery_percentage_high:before {
    content: "\eaf7";
  }
  .icon-Percentage:before {
    content: "\eaf8";
  }
  .icon-Smartphone_v2:before {
    content: "\eaf9";
  }
  .icon-Cloud:before {
    content: "\eafa";
  }
  .icon-Water_valve_on:before {
    content: "\eafb";
  }
  .icon-Water_valve_off:before {
    content: "\eafc";
  }
  .icon-No_Sos:before {
    content: "\eafd";
  }
  .icon-Button_down:before {
    content: "\eafe";
  }
  .icon-Button_up:before {
    content: "\eaff";
  }
  .icon-Button:before {
    content: "\eb00";
  }
  .icon-Garage_open:before {
    content: "\eb01";
  }
  .icon-Garage_closed:before {
    content: "\eb02";
  }
  .icon-CO_Detect_on:before {
    content: "\eb03";
  }
  .icon-Scene:before {
    content: "\eb04";
  }
  .icon-CO_Detect_off:before {
    content: "\eb05";
  }
  .icon-Smartvest_open:before {
    content: "\eb06";
  }
  .icon-Smartvest_closed:before {
    content: "\eb07";
  }
  .icon-Smartvest_intern_active:before {
    content: "\eb08";
  }
  .icon-GroupPowerOn .path1:before {
    content: "\eb09";
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path2:before {
    content: "\eb0a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path3:before {
    content: "\eb0b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path4:before {
    content: "\eb0c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path5:before {
    content: "\eb0d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path6:before {
    content: "\eb0e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path7:before {
    content: "\eb0f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path8:before {
    content: "\eb10";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path9:before {
    content: "\eb11";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path10:before {
    content: "\eb12";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path11:before {
    content: "\eb13";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path12:before {
    content: "\eb14";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path13:before {
    content: "\eb15";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOn .path14:before {
    content: "\eb16";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-GroupPowerOn .path15:before {
    content: "\eb17";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-GroupPowerOff .path1:before {
    content: "\eb18";
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path2:before {
    content: "\eb19";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path3:before {
    content: "\eb1a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path4:before {
    content: "\eb1b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path5:before {
    content: "\eb1c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path6:before {
    content: "\eb1d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path7:before {
    content: "\eb1e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path8:before {
    content: "\eb1f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path9:before {
    content: "\eb20";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path10:before {
    content: "\eb21";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path11:before {
    content: "\eb22";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path12:before {
    content: "\eb23";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path13:before {
    content: "\eb24";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-GroupPowerOff .path14:before {
    content: "\eb25";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-RoomPowerOn .path1:before {
    content: "\eb26";
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path2:before {
    content: "\eb27";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path3:before {
    content: "\eb28";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-RoomPowerOn .path4:before {
    content: "\eb29";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-RoomPowerOn .path5:before {
    content: "\eb2a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path6:before {
    content: "\eb2b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path7:before {
    content: "\eb2c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path8:before {
    content: "\eb2d";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path9:before {
    content: "\eb2e";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path10:before {
    content: "\eb2f";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path11:before {
    content: "\eb30";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOn .path12:before {
    content: "\eb31";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path1:before {
    content: "\eb32";
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path2:before {
    content: "\eb33";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path3:before {
    content: "\eb34";
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  .icon-RoomPowerOff .path4:before {
    content: "\eb35";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path5:before {
    content: "\eb36";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path6:before {
    content: "\eb37";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path7:before {
    content: "\eb38";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path8:before {
    content: "\eb39";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path9:before {
    content: "\eb3a";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path10:before {
    content: "\eb3b";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  .icon-RoomPowerOff .path11:before {
    content: "\eb3c";
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
  